import { call, put, select } from 'redux-saga/effects';
import { signalingWhiteboardDataReceived } from 'features/whiteboard/actions';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import {
  activeWhiteboardsCollectionUpdated,
  selectActiveWhiteboard,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onWhiteboardDataReceivedSaga(
  action: ReturnType<typeof signalingWhiteboardDataReceived>
) {
  board.init(action.payload);

  const currentWhiteboardData: ContentLibraryOpenedWhiteboard = yield select(
    selectActiveWhiteboard,
    action.payload.id
  );

  yield put(
    activeWhiteboardsCollectionUpdated({
      [action.payload.id]: {
        ...currentWhiteboardData,
        ...action.payload,
      },
    })
  );

  const canEditWhiteboard: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard);

  if (canEditWhiteboard) {
    yield call(board.watch);
  } else {
    yield call(board.stop);
  }
}
