import { call, put, select } from 'redux-saga/effects';
import { Action } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { addActionNotification } from 'features/notifications/addActionNotification';
import { SCREENSHARE_LIMIT_REACHED_NOTIFICATION_ID } from 'features/notifications/constants';
import { selectNotificationById } from 'features/notifications/notificationsSlice';
import { closeActionNotification } from 'features/notifications/thunks/closeActionNotification';
import { ActionNotification } from 'features/notifications/types';
import { updateActionNotification } from 'features/notifications/updateActionNotification';
import { screenshareLimitReachedNotificationUpdateRequested } from 'features/notifications/actions';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { User, UserId } from 'features/users/types';
import {
  screenshareLimitedDismissed,
  selectScreenshareLimitedIds,
  selectUserById,
} from 'features/users/usersSlice';

export function* handleScreenshareLimitReachedNotificationSaga(
  action: ReturnType<typeof screenshareLimitReachedNotificationUpdateRequested>
) {
  const limit: number = action.payload;

  const canManageScreenshare: boolean = yield call(
    hasPermissionsSaga,
    PermissionTypes.manageScreenshare,
    {
      targetRole: {
        coverage: 'any',
      },
    }
  );

  if (!canManageScreenshare) {
    return;
  }

  const screenshareLimitedIds: UserId[] = yield select(selectScreenshareLimitedIds);
  const screenshareLimitedUserCount = screenshareLimitedIds.length;

  const activeNotification: ActionNotification = yield select(
    selectNotificationById,
    SCREENSHARE_LIMIT_REACHED_NOTIFICATION_ID
  );

  if (screenshareLimitedUserCount === 0) {
    if (activeNotification) {
      const cleanupNotificationThunk: Action = yield call(
        closeActionNotification,
        SCREENSHARE_LIMIT_REACHED_NOTIFICATION_ID
      );

      yield put(cleanupNotificationThunk);
    }

    return;
  }

  const user: User = yield select(selectUserById, screenshareLimitedIds[0]);

  if (!activeNotification) {
    const actionNotificationThunk: Action = yield call(
      addActionNotification,
      {
        content: i18n.t('notifications:screenshare_limit_reached.content', {
          username: user.name,
          count: limit,
          context: screenshareLimitedUserCount > 1 ? 'grouped' : undefined,
        }),
        preventCloseOnAction: true,
        userAgnostic: true,
        cancelAction: screenshareLimitedDismissed,
      },
      {
        toastId: SCREENSHARE_LIMIT_REACHED_NOTIFICATION_ID,
      }
    );

    yield put(actionNotificationThunk);

    return;
  }

  const updateActionNotificationThunk: Action = yield call(
    updateActionNotification,
    activeNotification.id,
    {
      content: i18n.t('notifications:screenshare_limit_reached.content', {
        username: user.name,
        count: limit,
        context: screenshareLimitedUserCount > 1 ? 'grouped' : undefined,
      }),
      preventCloseOnAction: true,
      userAgnostic: true,
    }
  );

  yield put(updateActionNotificationThunk);
}
