import { call, put, select } from 'redux-saga/effects';
import { fileViewed } from 'features/content-library/actions';
import { selectActiveWhiteboard, fileOpened } from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { requestWhiteboardData } from 'features/whiteboard/actions';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* onWhiteboardViewedSaga(action: ReturnType<typeof fileViewed>) {
  const id = action.payload;
  const whiteboard: ContentLibraryOpenedWhiteboard | undefined = yield select(
    selectActiveWhiteboard,
    id
  );

  if (whiteboard) {
    yield put(fileOpened(whiteboard));

    const previousWhiteboardId = board.whiteboardId;

    board.init(whiteboard);

    if (previousWhiteboardId !== whiteboard.id) {
      yield put(requestWhiteboardData(whiteboard.id));
    }

    const canEditWhiteboard: boolean = yield call(
      hasPermissionsSaga,
      PermissionTypes.editWhiteboard
    );

    if (canEditWhiteboard) {
      yield call(board.watch);
    }
  }
}
