import { Box } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { useGridPanelDragHandle } from 'features/layout/GridPanel/useGridPanelDragHandle';

export const GridPanelContainer = ({
  children,
  id,
  sx,
}: {
  children: React.ReactNode | React.ReactChild;
  id: string;
  sx?: SxProps;
}) => {
  const hasDragHandle = useGridPanelDragHandle();

  return (
    <Box
      id={id}
      sx={[
        {
          background: (theme) => theme.palette.grey['50'],
          position: 'absolute',
          width: `calc(100% - ${hasDragHandle ? '18px' : '8px'})`,
          height: 'calc(100% - 48px)',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          overflow: 'hidden',
          display: 'flex',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
};
