import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import { GridPanelContainer } from 'features/layout/GridPanel/GridPanelContainer';
import { PanelContentSelector } from 'features/layout/GridPanel/PanelContentSelector';
import { screenshareSelected } from 'features/streaming/actions';
import RemoteAudioWrapper from 'features/streaming/components/media/remote-stream/RemoteAudioWrapper';
import StreamDisplay from 'features/streaming/components/stream-display/StreamDisplay';
import BaseLoadingIndicator from 'features/room/components/BaseLoadingIndicator';
import { selectSelectedScreenshare } from 'features/streaming/streamingSlice';
import { makeSourceKey, sourceMatches } from 'features/streaming/utils';
import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const ScreensharePanel = () => {
  const dispatch = useAppDispatch();

  const screenshares = useAppSelector(selectScreenshareStreams);

  useEffect(() => {
    if (!screenshares.length) {
      dispatch(gridPanelVoided('screenshare'));
    }
  }, [screenshares, dispatch]);

  const selectedScreenshare = useAppSelector(selectSelectedScreenshare);

  const activeScreenshareEntry = useMemo(
    () => screenshares.find((screenshare) => sourceMatches(screenshare, selectedScreenshare)),
    [screenshares, selectedScreenshare]
  );

  const selectScreenshare = (idx: number) => {
    const screenshare = screenshares[idx];
    if (screenshare?.feedId) {
      dispatch(screenshareSelected({ feedId: screenshare.feedId, userId: screenshare.userId }));
    }
  };

  return (
    <GridPanelContainer
      sx={{ alignItems: 'center', justifyContent: 'center' }}
      id="screenshare-container"
    >
      {activeScreenshareEntry ? (
        <>
          <PanelContentSelector
            items={screenshares}
            activeItem={activeScreenshareEntry}
            onSelect={selectScreenshare}
            renderLabel={(screenshare) =>
              `Screenshare (${screenshare.kind === 'screenshare-local' ? 'You' : screenshare.user?.name})`
            }
          />
          <StreamDisplay source={activeScreenshareEntry} maximized />
          {activeScreenshareEntry?.kind !== 'screenshare-local' && (
            <RemoteAudioWrapper
              source={activeScreenshareEntry}
              key={makeSourceKey(activeScreenshareEntry)}
            />
          )}
        </>
      ) : (
        <BaseLoadingIndicator />
      )}
    </GridPanelContainer>
  );
};
