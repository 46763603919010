import { fileViewed } from 'features/content-library/actions';
import { fileOpened, selectActiveFile } from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { put, select } from 'redux-saga/effects';

export function* onContentLibraryFileViewedSaga(action: ReturnType<typeof fileViewed>) {
  const id = action.payload;
  const file: ContentLibraryOpenedFile | undefined = yield select(selectActiveFile, id);

  if (file) {
    yield put(fileOpened(file));
  }
}
