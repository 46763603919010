import { createAction } from '@reduxjs/toolkit';
import { ReceivedWhiteboardData, RejectedWhiteboardData } from './types';

export const fileUploadPrepared = createAction('whiteboard/fileUploadPrepared');
export const signalingWhiteboardDataReceived = createAction<ReceivedWhiteboardData>(
  'signaling/whiteboardDataReceived'
);
export const signalingWhiteboardDataRequestRejected = createAction<RejectedWhiteboardData>(
  'signaling/whiteboardDataRequestRejected'
);
export const requestWhiteboardData = createAction<string>('requestWhiteboardData');
