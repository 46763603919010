export const ACTION_NOTIFICATION_CONTAINER_ID = 'action-notifications-container';
export const SIMPLE_NOTIFICATION_CONTAINER_ID = 'simple-notifications-container';
export const ACTION_NOTIFICATION_LIMIT = 3;

export const INVITE_PARTICIPANT_SUGGESTION_ID = 'invite-participant-suggestion';
export const WAITING_ENTRY_NOTIFICATION_ID = 'waiting-entry-notification';
export const HAND_RAISED_NOTIFICATION_ID = 'hand-raised-notification';
export const WEAK_CONNECTION_NOTIFICATION_ID = 'weak-connection-notification';

export const USER_JOIN_NOTIFICATION_ID = 'user-join-notification';

export const USER_LEFT_NOTIFICATION_ID = 'user-left-notification';

export const REMOTE_BROADCASTER_LIMIT_REACHED_NOTIFICATION_ID =
  'remote-broadcaster-limit-reached-notification';

export const SCREENSHARE_LIMIT_REACHED_NOTIFICATION_ID = 'screenshare-limit-reached-notification';

export const SESSION_ENDS_SOON_NOTIFICATION_ID = 'session-end-notification';
