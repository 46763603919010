import { IconButtonProps, IconButton } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import Icon from 'components/Icon';
import React from 'react';

const Button = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'disableInteraction',
})(({ theme }) => ({
  transition: theme.transitions.create('color'),
  fontSize: 24,
  width: 32,
  height: 32,
  border: `1px solid ${theme.palette.grey[300]}`,
  '&:focus-visible': {
    outline: 'none',
    color: lighten(theme.palette.common.black, 0.4),
  },
  marginLeft: '.5rem',
  '&:after': {
    content: '""',
    backgroundColor: theme.palette.grey[200], //alpha(, ),
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
    borderRadius: '50%',
  },
}));

type GridPanelControlProps = {
  icon: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
} & Partial<IconButtonProps>;

export const GridPanelControl = ({ onClick, icon, ...rest }: GridPanelControlProps) => (
  <Button onClick={onClick} className="grid-panel-close-control" {...rest}>
    <Icon name={icon} />
  </Button>
);
