import React from 'react';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { usePushContentControl } from './usePushContentControl';

export const GridPanelPushControl = () => {
  const { controlEnabled, handleClick, label } = usePushContentControl();

  if (!controlEnabled) {
    return null;
  }

  return <GridPanelControl aria-label={label} icon="box-arrow" onClick={handleClick} />;
};
