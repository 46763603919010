import { Box } from '@mui/material';
import { getContrastRatio, styled } from '@mui/material/styles';
import {
  gridPanelTabChanged,
  selectAvailableTabs,
  selectGridPanel,
} from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';

const Tab = styled(Box)<{ active: boolean }>(({ theme, active }) => {
  const activeColor =
    getContrastRatio(theme.palette.primary.main, theme.palette.common.white) > 1
      ? theme.palette.primary.main
      : theme.palette.common.black;

  return {
    color: active ? activeColor : theme.palette.common.black,
    padding: '.5rem 1rem',
    borderBottom: `2px solid ${active ? activeColor : 'transparent'}`,
    cursor: 'pointer',
  };
});

// const PushAndLockControl = () => {
//   const { t } = useTranslation('room');
//
//   const [active, setActive] = useState(false);
//
//   return (
//     <Button
//       type="button"
//       color="grayscale"
//       onClick={() => setActive((old) => !old)}
//       sx={(theme) => ({
//         position: 'absolute',
//         left: 0,
//         top: 0,
//         borderRadius: '12px',
//         color: theme.palette.text.primary,
//       })}
//     >
//       <Icon name={active ? 'unlock' : 'lock'} sx={{ fontSize: '24px', marginRight: 1 }} />
//       {active ? t('grid.unlock') : t('grid.push_and_lock')}
//     </Button>
//   );
// };

export const GridPanelTabs = () => {
  const screenshareStreams = useAppSelector(selectScreenshareStreams);

  const dispatch = useAppDispatch();
  const openTab = useAppSelector(selectGridPanel);
  const availableTabs = useAppSelector(selectAvailableTabs);

  const handleTabChange = (panel: GridPanelName) => {
    dispatch(gridPanelTabChanged(panel));
  };

  const screenshareTabAvailable = screenshareStreams.length;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: `1px solid ${theme.palette.grey[600]}`,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        overflow: 'hidden',
        position: 'relative',
      })}
    >
      {/*<PushAndLockControl />*/}
      {screenshareTabAvailable ? (
        <Tab onClick={() => handleTabChange('screenshare')} active={openTab === 'screenshare'}>
          Screenshares
        </Tab>
      ) : null}
      {availableTabs.contentLibrary ? (
        <Tab
          onClick={() => handleTabChange('contentLibrary')}
          active={openTab === 'contentLibrary'}
        >
          Files
        </Tab>
      ) : null}
      {availableTabs.whiteboard ? (
        <Tab onClick={() => handleTabChange('whiteboard')} active={openTab === 'whiteboard'}>
          Whiteboards
        </Tab>
      ) : null}
    </Box>
  );
};
