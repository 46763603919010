import { cleanupSelectedScreenshareSaga } from 'features/streaming/sagas/cleanupSelectedScreenshareSaga';
import { call, fork, put, select } from 'redux-saga/effects';
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { SignalingUserLeftPayload, User } from 'features/users/types';
import { selectUserById } from 'features/users/usersSlice';
import { selectIsRecorderSession } from 'features/recorder/recorderSlice';
import { eventBus } from 'utils/eventBus';
import { cleanupUsers } from 'features/users/thunks/cleanupUsers';
import { RTCClient } from 'utils/webrtc';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { cleanupE2eeUser } from 'features/e2ee/cleanupE2eeUser';
import { refreshEncryptionKeys } from 'features/e2ee/sagas/refreshEncryptionKeys';
import { board } from 'utils/whiteboard/BoardStateManager';
import {
  raiseHandNotificationUpdateRequested,
  userLeftNotificationTriggered,
} from 'features/notifications/actions';
import { refineSframeStatus } from 'features/e2ee/sagas/refineSframeStatus';
import { selectRoomId } from 'features/room/roomSlice';

export function* onUserLeftSaga(action: PayloadAction<SignalingUserLeftPayload>) {
  const { id: userId, roomId: payloadRoomId } = action.payload;

  const user: User = yield select(selectUserById, userId);
  if (!user) {
    // prevent possible race-condition from the backend
    return;
  }

  yield call(cleanupSelectedScreenshareSaga, action);

  const isRecorderSession: boolean = yield select(selectIsRecorderSession);
  if (!isRecorderSession) {
    yield put(
      userLeftNotificationTriggered({
        id: userId,
      })
    );

    yield call(eventBus.sendMessage, 'userLeft', { userId }, { reason: 'generic' });
  }

  const mainRoomId: string = yield select(selectRoomId);
  const roomId = payloadRoomId || mainRoomId;

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  // cleanup E2EE stuff
  if (e2eeEnabled) {
    yield call(refineSframeStatus);
    yield call(cleanupE2eeUser, user.id, roomId, user.e2eeId!);
  }

  yield call(RTCClient.receivingFeed.unloadFeedAudio, userId);

  const cleanupUserThunk: Action = yield call(cleanupUsers, [{ userId, roomId }]);
  yield put(cleanupUserThunk);

  yield call(board.removeRemotePointer, userId);

  if (!isRecorderSession) {
    // we have to wait until the user is completely removed from the store
    if (e2eeEnabled) {
      yield fork(refreshEncryptionKeys);
    }

    // update raise hand notification
    yield put(raiseHandNotificationUpdateRequested({ reason: 'user-left' }));
  }
}
