import { put, select } from 'redux-saga/effects';
import { SourceDetails } from 'features/layout/types';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import { screenshareSelected } from 'features/streaming/actions';
import { gridPanelOpened, gridPanelVoided } from 'features/layout/features/content/contentSlice';

export function* openLastActiveScreenshareSaga(feedId?: string) {
  const screenshares: (SourceDetails & { timestamp: number })[] =
    yield select(selectScreenshareStreams);

  const filteredScreenshares = screenshares.filter((item) => item.feedId !== feedId);

  if (filteredScreenshares.length > 0) {
    const latestOpenedScreensahre = filteredScreenshares.reduce((latest, current) =>
      current.timestamp > latest.timestamp ? current : latest
    );

    yield put(
      screenshareSelected({
        feedId: latestOpenedScreensahre?.feedId ?? '',
        userId: latestOpenedScreensahre.userId,
      })
    );

    yield put(gridPanelOpened('screenshare'));
  } else {
    yield put(gridPanelVoided('screenshare'));
  }
}
