import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';
import { openLastActiveWhiteboardSaga } from 'features/content-library/sagas/openLastActiveWhiteboardSaga';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { selectContentLibraryActiveFiles } from 'features/content-library/contentLibrarySlice';
import { signalingContentLibraryFileClosed } from 'features/content-library/actions';
import { GridPanelName } from 'features/layout/features/content/types';
import { gridPanelVoided, selectGridPanel } from 'features/layout/features/content/contentSlice';
import { call, put, select } from 'redux-saga/effects';

export function* onContentLibraryFileClosedSaga(
  action: ReturnType<typeof signalingContentLibraryFileClosed>
) {
  const currentOpenedPanel: GridPanelName = yield select(selectGridPanel);

  const files: ContentLibraryOpenedFile[] = yield select(selectContentLibraryActiveFiles);

  const filteredFiles = files.filter((item) => item.id !== action.payload.id);

  if (currentOpenedPanel === 'contentLibrary') {
    yield call(openLastActiveFileSaga, action.payload.id);

    if (filteredFiles.length === 0) {
      yield call(openLastActiveWhiteboardSaga);
    }
  }

  if (filteredFiles.length === 0) {
    yield put(gridPanelVoided('contentLibrary'));
  }
}
