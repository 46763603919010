import { PayloadAction } from '@reduxjs/toolkit';
import { selectAppReconnecting } from 'features/application/applicationSlice';
import { orderChanged } from 'features/layout/actions';
import {
  localStreamCollapsed,
  selectLocalStreamMinimized,
  selectMinimizeLocalOnJoin,
} from 'features/layout/features/config/configSlice';
import { selectMaximizedStream } from 'features/layout/features/content/contentSlice';
import {
  dimensionsChanged,
  selectRenderAreaDimensions,
} from 'features/layout/features/dimensions/dimensionsSlice';
import { LayoutDimensionsState } from 'features/layout/features/dimensions/types';
import { fitToRenderArea } from 'features/layout/fitToRenderArea';
import { findRoomPublishersSaga } from 'features/layout/sagas/findRoomPublishersSaga';
import { prepareExposedContentSaga } from 'features/layout/sagas/prepareExposedContentSaga';
import { SourceDetails } from 'features/layout/types';
import { selectLocalBroadcastPermissions } from 'features/permissions/permissionsSlice';
import { sourceMatches } from 'features/streaming/utils';
import { call, put, select } from 'redux-saga/effects';
import { SignalingRoomUser } from 'services/signaling';
import { orderRoomUsers } from 'utils/layout';
import { RoomJoinedPayload } from 'features/room/types';

export function* prepareRoomLayoutSaga(action: PayloadAction<RoomJoinedPayload>) {
  const publishers: SignalingRoomUser[] = yield call(findRoomPublishersSaga, action.payload.users);
  const orderedRoomUsers: SourceDetails[] = yield call(orderRoomUsers, publishers);
  yield put(orderChanged(orderedRoomUsers));
  const localCanBroadcast: boolean = yield select(selectLocalBroadcastPermissions);
  const localTileMinimized: boolean = yield select(selectLocalStreamMinimized);

  yield call(prepareExposedContentSaga, action);

  const maximizedStream: SourceDetails = yield select(selectMaximizedStream);

  const gridPublishers = orderedRoomUsers.filter(
    (source) => !sourceMatches(source, maximizedStream)
  );

  const renderAreaDimensions: { width: number; height: number } = yield select(
    selectRenderAreaDimensions
  );

  const showLocalTile =
    localCanBroadcast && !localTileMinimized && maximizedStream?.kind !== 'local';

  const totalStreams = gridPublishers.length + (showLocalTile ? 1 : 0);

  const dimensions: LayoutDimensionsState = yield call(
    fitToRenderArea,
    renderAreaDimensions.width,
    renderAreaDimensions.height,
    totalStreams,
    'desktop'
  );

  yield put(dimensionsChanged(dimensions));

  const isReconnecting: boolean = yield select(selectAppReconnecting);

  if (!isReconnecting) {
    const minimizeLocal: boolean = yield select(selectMinimizeLocalOnJoin);
    if (minimizeLocal) {
      yield put(localStreamCollapsed());
    }
  }
}
