import { useTranslation } from 'react-i18next';
import { contentPushed } from 'features/layout/features/content/actions';
import { usePermissions } from 'features/permissions/usePermissions';
import { PermissionTypes } from 'features/permissions/types';
import { useAppDispatch } from 'store/hooks';

export const usePushContentControl = () => {
  const { hasPermissions } = usePermissions();

  const controlEnabled = hasPermissions(PermissionTypes.pushContent);

  const { t } = useTranslation('room');

  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (controlEnabled) {
      dispatch(contentPushed());
    }
  };

  return {
    label: t('grid.push_content'),
    handleClick,
    controlEnabled,
  };
};
