import { call, put } from 'redux-saga/effects';
import { openModal } from 'features/modal/modalSlice';
import { screenshareLimitReachedNotificationUpdateRequested } from 'features/notifications/actions';
import { signalingScreenshareLimitReached } from 'features/room/actions';
import { RTCClient } from 'utils/webrtc';

export function* onScreenshareLimitReachedSaga(
  action: ReturnType<typeof signalingScreenshareLimitReached>
) {
  const { limit, id: initiatorId } = action.payload;

  if (initiatorId) {
    yield put(screenshareLimitReachedNotificationUpdateRequested(limit));

    return;
  }

  // local user
  yield put(openModal('screenshareLimitReached', { limit }));

  yield call(RTCClient.screensharingFeed.stopScreenshare);
}
