import { signalingWhiteboardOpened } from 'features/layout/features/content/actions';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { PermissionTypes } from 'features/permissions/types';
import { call, put, select } from 'redux-saga/effects';
import { board } from 'utils/whiteboard/BoardStateManager';
import { eventBus } from 'utils/eventBus';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { E2EEManager } from 'features/e2ee/E2EEManager';
import { selectRoomId } from 'features/room/roomSlice';
import { exchangeCreatedWhiteboardKeySaga } from 'features/e2ee/sagas/exchangeCreatedWhiteboardKeySaga';
import { AesCm256EncryptionKey } from 'features/e2ee/AesCm256EncryptionKey';
import { waitForWhiteboardKeyDecryptionSaga } from 'features/layout/sagas/waitForTheWhiteboardKeyDecryptionSaga';
import { decryptWBRecords } from 'utils/whiteboard/decryptWBRecords';
import { TLRecord } from '@digitalsamba/tldraw';
import { parseBoardState } from 'utils/whiteboard/parseBoardState';
import * as Sentry from '@sentry/react';
import { logger } from 'utils/logger';

export function* onWhiteboardOpenedSaga(action: ReturnType<typeof signalingWhiteboardOpened>) {
  const { initiator, id, shapes } = action.payload;

  const roomId: string = yield select(selectRoomId);

  // @TODO extract a function ?
  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);
  if (e2eeEnabled) {
    const localUserId: UserId = yield select(selectLocalUserId);

    if (localUserId === initiator.id) {
      yield call(E2EEManager.whiteboard.generateKey, id, roomId);

      yield call(exchangeCreatedWhiteboardKeySaga, id);
    } else {
      yield call(waitForWhiteboardKeyDecryptionSaga, id, roomId);
    }
  }

  if (shapes?.length) {
    let parsedBoardState: TLRecord[] = [];

    if (e2eeEnabled) {
      const whiteboardKey: AesCm256EncryptionKey | undefined = yield call(
        E2EEManager.whiteboard.getKey,
        id,
        roomId
      );

      const decryptionKey = whiteboardKey?.encryptionKey;
      if (!decryptionKey) {
        throw new Error(`Unable to retrieve decryption key for whiteboard ID: ${id}`);
      }

      try {
        parsedBoardState = yield call(decryptWBRecords, shapes, decryptionKey);
      } catch (error) {
        const errorMessage = `Cannot decrypt whiteboard state for id=${id}, roomId=${roomId}`;

        Sentry.captureException(error);
        logger.remote({ system: true, capture: 'e2ee' }).error(errorMessage);
      }
    } else {
      parsedBoardState = yield call(parseBoardState, action.payload.shapes);
    }

    // TODO refactor and use init method here
    yield call(board.setState, parsedBoardState);
  }

  board.init(action.payload);

  const canEditWhiteboard: boolean = yield call(hasPermissionsSaga, PermissionTypes.editWhiteboard);

  if (canEditWhiteboard) {
    yield call(board.watch);
  } else {
    yield call(board.stop);
  }

  yield call(eventBus.sendMessage, 'whiteboardOpened', undefined, action.payload);

  yield put(gridPanelOpened('whiteboard'));
}
