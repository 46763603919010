import { Box, Divider, ListItemText, MenuItem } from '@mui/material';
import Icon from 'components/Icon';
import Menu from 'components/Menu/Menu';
import { Chip } from 'features/streaming/components/stream-display/StreamBadge';
import { useBreakpoints } from 'hooks/useBreakpoints';
import React, { useState } from 'react';
import { SxProps } from '@mui/material/styles';

type ExtraItem = {
  title: string;
  action: () => void;
};

type PanelContentSelectorProps = {
  extraItems?: ExtraItem[];
  items: any[];
  activeItem: any;
  sx?: SxProps;
  renderLabel: (item: any) => string;
  onSelect: (idx: number) => void;
};

export const PanelContentSelector = ({
  extraItems = [],
  items,
  activeItem,
  onSelect,
  renderLabel,
  sx = [],
}: PanelContentSelectorProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => setMenuOpen(false);

  const { isMobile } = useBreakpoints();

  const isDropDownAvailable = items.length > 1 || extraItems?.length;

  return (
    <Box
      className={!isMobile ? 'hover-child' : ''}
      sx={[
        {
          position: 'absolute',
          top: (theme) => theme.spacing(2),
          left: '50%',
          width: 'auto',
          transform: 'translateX(-50%)',
          textAlign: isMobile ? 'left' : 'center',
          padding: (theme) => theme.spacing(0, 2),
          zIndex: 1000,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Menu
        id="file-panel-content-selector"
        open={menuOpen}
        onClose={closeMenu}
        placement={isMobile ? 'bottom-start' : 'bottom'}
        PopperProps={{ disablePortal: true }}
        trigger={
          <button
            type="button"
            className="button-appearance-none"
            style={{ padding: 0, cursor: 'pointer' }}
            onClick={() => {
              if (isDropDownAvailable) {
                setMenuOpen((open) => !open);
              } else {
                setMenuOpen(false);
              }
            }}
          >
            <Chip
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>{renderLabel(activeItem)}</span>
                  {isDropDownAvailable ? (
                    <Icon
                      name="dropdown-arrow"
                      sx={{
                        fontSize: '32px',
                        marginRight: '-10px',
                        transform: 'translateY(-2px)',
                      }}
                    />
                  ) : null}
                </div>
              }
            />
          </button>
        }
        PaperProps={{
          sx: (theme) => ({
            backgroundColor: theme.palette.grey['900'],
          }),
        }}
        sx={(theme) => ({
          color: theme.palette.common.white,
        })}
      >
        {extraItems.map((item) => (
          <MenuItem key={item.title} onClick={item.action}>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
        <Divider hidden={extraItems.length === 0} />
        <Box sx={{ maxHeight: '285px', overflow: 'auto' }}>
          {items.map((row, idx) =>
            row ? (
              <MenuItem key={idx} onClick={() => onSelect(idx)}>
                <ListItemText>{renderLabel(row)}</ListItemText>
              </MenuItem>
            ) : null
          )}
        </Box>
      </Menu>
    </Box>
  );
};
