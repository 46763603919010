import {
  fileOpened,
  selectContentLibraryActiveFiles,
} from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { gridPanelOpened, gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { put, select } from 'redux-saga/effects';

export function* openLastActiveFileSaga(id?: string) {
  const currentFiles: ContentLibraryOpenedFile[] = yield select(selectContentLibraryActiveFiles);

  const files = currentFiles.filter((item) => item.id !== id);

  if (files.length === 0) {
    yield put(gridPanelVoided('contentLibrary'));
  }

  if (files.length > 0) {
    const latestFile: ContentLibraryOpenedFile = files.reduce((latest, current) => {
      const latestDate = new Date(latest.date);
      const currentDate = new Date(current.date);

      return currentDate > latestDate ? current : latest;
    });

    yield put(fileOpened(latestFile));
    yield put(gridPanelOpened('contentLibrary'));
  }
}
