import {
  gridPanelTabChanged,
  selectGridPanel,
} from 'features/layout/features/content/contentSlice';
import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';
import { openLastActiveWhiteboardSaga } from 'features/content-library/sagas/openLastActiveWhiteboardSaga';
import { GridPanelName } from 'features/layout/features/content/types';
import { call, select } from 'redux-saga/effects';

export function* onGridPanelTabChangedSaga(action: ReturnType<typeof gridPanelTabChanged>) {
  const currentGridPanel: GridPanelName = yield select(selectGridPanel);

  if (action.payload === 'contentLibrary' && currentGridPanel === 'contentLibrary') {
    yield call(openLastActiveFileSaga);
  }

  if (action.payload === 'whiteboard' && currentGridPanel === 'whiteboard') {
    yield call(openLastActiveWhiteboardSaga);
  }
}
