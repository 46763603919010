import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { selectContentLibraryActiveWhiteboards } from 'features/content-library/contentLibrarySlice';
import { openLastActiveWhiteboardSaga } from 'features/content-library/sagas/openLastActiveWhiteboardSaga';
import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';
import { signalingWhiteboardClosed } from 'features/layout/features/content/actions';
import { gridPanelVoided, selectGridPanel } from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';

export function* onWhiteboardClosedSaga(action: ReturnType<typeof signalingWhiteboardClosed>) {
  const currentOpenedPanel: GridPanelName = yield select(selectGridPanel);

  const whiteboards: ContentLibraryOpenedWhiteboard[] = yield select(
    selectContentLibraryActiveWhiteboards
  );

  const filteredWhiteboards = whiteboards.filter((item) => item.id !== action.payload.id);

  if (currentOpenedPanel === 'whiteboard') {
    yield call(openLastActiveWhiteboardSaga, action.payload.id);

    if (filteredWhiteboards.length === 0) {
      yield call(openLastActiveFileSaga);
    }
  }

  if (filteredWhiteboards.length === 0) {
    yield put(gridPanelVoided('whiteboard'));
  }

  yield call(eventBus.sendMessage, 'whiteboardClosed', undefined, { id: action.payload.id });
}
