import { call, select, put } from 'redux-saga/effects';
import { PermissionTypes } from 'features/permissions/types';
import { selectFeatureFlag } from 'features/feature-set/featuresSlice';
import { hasPermissionsSaga } from 'features/permissions/sagas/hasPermissionsSaga';
import { ContentLibraryFileEntry, ContentLibraryOpenedFile } from 'features/content-library/types';
import { eventBus } from 'utils/eventBus';
import {
  fileClosed,
  selectContentLibraryOpenedFile,
  selectFileByLibraryType,
} from 'features/content-library/contentLibrarySlice';
import { contentLibraryFileOpenRequested } from 'features/content-library/actions';
import { whiteboardClosed } from 'features/layout/features/content/actions';

export function* onToggleLibraryFileSaga(show?: boolean, data?: { id: string }) {
  const controlEnabled: boolean = yield select(selectFeatureFlag, 'contentLibrary');

  if (!controlEnabled) {
    yield call(
      eventBus.notAllowedError,
      'Content library is disabled. You’ll need to edit this room’s properties to enable it.',
      'contentLibrary'
    );

    return;
  }

  const canPresent: boolean = yield call(hasPermissionsSaga, PermissionTypes.presentContentLibrary);

  if (!canPresent) {
    yield call(
      eventBus.forbiddenActionError,
      'Forbidden action. This participant is not allowed to control content library',
      'contentLibrary'
    );

    return;
  }

  let personal = false;

  if (data?.id) {
    const roomExistFile: ContentLibraryFileEntry = yield select(
      selectFileByLibraryType,
      data.id,
      'room'
    );

    if (!roomExistFile) {
      const personalExistFile: ContentLibraryFileEntry = yield select(
        selectFileByLibraryType,
        data.id,
        'personal'
      );

      if (!personalExistFile) {
        yield call(eventBus.error, {
          name: 'contentLibrary',
          message: `File not found. No file exists in the library for the provided ID (${data.id}).`,
        });

        return;
      }

      personal = true;
    }
  }

  const openedFile: ContentLibraryOpenedFile = yield select(selectContentLibraryOpenedFile);

  show = typeof show === 'undefined' ? !openedFile : show;

  if (show && data?.id) {
    yield put(contentLibraryFileOpenRequested({ id: data.id, personal }));
  } else if (!show && openedFile) {
    const isMatchingFile = data?.id && data.id === openedFile.id;

    if (isMatchingFile || !data?.id) {
      const action =
        openedFile.type === 'whiteboard'
          ? whiteboardClosed(openedFile.id)
          : fileClosed(openedFile.id);
      yield put(action);
    }
  }
}
