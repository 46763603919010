import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WhiteboardState } from 'features/whiteboard/types';
import { signalingWhiteboardOpened } from 'features/layout/features/content/actions';
import { whiteboardViewed } from 'features/content-library/actions';
import { RootState } from 'store/store';

export const initialState: WhiteboardState = {
  showLoader: false,
  uploadProgress: 0,
  isUploading: false,
  whiteboardId: null,
};

const whiteboardSlice = createSlice({
  name: 'whiteboard',
  initialState,
  reducers: {
    uploadProgressChanged(state, action: PayloadAction<number>) {
      if (state.uploadProgress === 0 && !state.isUploading) {
        state.isUploading = true;
      }
      if (state.uploadProgress !== 0 && action.payload === 0) {
        state.isUploading = false;
      }
      state.uploadProgress = action.payload;
    },
    loaderDisplayChanged(state, action: PayloadAction<boolean>) {
      state.showLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signalingWhiteboardOpened, (state, action) => {
      state.whiteboardId = action.payload.id;
    });
    builder.addCase(whiteboardViewed, (state, action) => {
      state.whiteboardId = action.payload;
    });
  },
});

export const { uploadProgressChanged, loaderDisplayChanged } = whiteboardSlice.actions;

export const selectWhiteboardOpen = (state: RootState) =>
  state.layout.content.gridPanel === 'whiteboard';

export const selectGridPanelOpen = (state: RootState) => !!state.layout.content.gridPanel;

export const selectUploadProgress = (state: RootState) => state.whiteboard.uploadProgress;

export const selectIsUploading = (state: RootState) => state.whiteboard.isUploading;

export const selectShowWhiteboardLoader = (state: RootState) => state.whiteboard.showLoader;

export const selectWhiteboardId = (state: RootState) => state.whiteboard.whiteboardId;

export default whiteboardSlice.reducer;
