import { selectContentLibraryActiveWhiteboards } from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { gridPanelOpened, gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { whiteboardViewed } from 'features/content-library/actions';
import { call, put, select } from 'redux-saga/effects';
import { board } from 'utils/whiteboard/BoardStateManager';

export function* openLastActiveWhiteboardSaga(id?: string) {
  const whiteboards: ContentLibraryOpenedWhiteboard[] = yield select(
    selectContentLibraryActiveWhiteboards
  );

  const filteredWhiteboards = whiteboards.filter((item) => item.id !== id);

  if (filteredWhiteboards.length > 0) {
    const latestOpenedWhiteboard: ContentLibraryOpenedWhiteboard = filteredWhiteboards.reduce(
      (latest, current) => {
        const latestDate = new Date(latest.date);
        const currentDate = new Date(current.date);

        return currentDate > latestDate ? current : latest;
      }
    );

    yield put(whiteboardViewed(latestOpenedWhiteboard.id));

    yield put(gridPanelOpened('whiteboard'));
  } else {
    yield put(gridPanelVoided('whiteboard'));

    yield call(board.reset);
  }
}
