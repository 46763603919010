import { screenshareSelected } from 'features/streaming/actions';
import {
  selectedScreenshareChanged,
  selectSelectedScreenshare,
} from 'features/streaming/streamingSlice';
import { SelectedScreenshare } from 'features/streaming/types';
import { UserId } from 'features/users/types';
import { selectLocalUserId } from 'features/users/usersSlice';
import { call, put, select } from 'redux-saga/effects';
import { RTCClient } from 'utils/webrtc';
import { batchToggleMediaTemplate, sendMessage } from 'utils/webrtc/messages';

export function* onScreenshareSelectedSaga(action: ReturnType<typeof screenshareSelected>) {
  const { userId, feedId } = action.payload;
  const localUserId: UserId = yield select(selectLocalUserId);

  const previousScreenshare: SelectedScreenshare | null = yield select(selectSelectedScreenshare);

  if (userId === previousScreenshare?.userId && previousScreenshare.feedId === feedId) {
    return;
  }

  const feed = RTCClient.receivingFeed.receiver?.plugin?.janusPlugin;

  // unsubscribe from previous screenshare;
  if (previousScreenshare) {
    const { streams } = RTCClient.receivingFeed.media.getFeedStreams(previousScreenshare.feedId);

    const request = Object.values(streams).map((stream) => ({
      mid: stream.subscriberMid || '-1',
      send: false,
    }));

    if (feed) {
      yield call(sendMessage, feed, batchToggleMediaTemplate(request));
    }
  }

  if (localUserId !== userId) {
    if (RTCClient.receivingFeed.subscribedScreenshares[feedId]) {
      const { streams } = RTCClient.receivingFeed.media.getFeedStreams(feedId);

      const request = Object.values(streams).map((stream) => ({
        mid: stream.subscriberMid || '-1',
        send: true,
      }));

      if (feed) {
        yield call(sendMessage, feed, batchToggleMediaTemplate(request));
      }
    } else {
      const streams = RTCClient.receivingFeed.availableScreenshares[feedId];

      if (streams?.length) {
        RTCClient.receivingFeed.subscribedScreenshares[feedId] = true;

        yield call(
          RTCClient.subscribe,
          {
            feedId,
            streams,
            id: userId,
          },
          true
        );

        yield call(RTCClient.enableScreensharingMedia, userId, streams);
      }
    }
  }

  yield put(selectedScreenshareChanged({ userId, feedId }));
}
