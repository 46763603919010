import { OpenedWhiteboard } from 'features/room/types';
import { ContentLibraryOpenedWhiteboard } from 'features/content-library/types';
import { SignalingWhiteboardOpenedPayload } from 'features/layout/features/content/types';

type WhiteboardData = OpenedWhiteboard | SignalingWhiteboardOpenedPayload;

export const formatToWhiteboardFile = (data: WhiteboardData): ContentLibraryOpenedWhiteboard => ({
  url: '',
  shapes: [],
  type: 'whiteboard',
  date: new Date().toISOString(),
  ...data,
});

export const formatWhiteboards = (
  data: Record<string, WhiteboardData>
): Record<string, ContentLibraryOpenedWhiteboard> =>
  Object.keys(data).reduce(
    (result, whiteboardId) => ({
      ...result,
      [whiteboardId]: formatToWhiteboardFile(data[whiteboardId]),
    }),
    {}
  );
