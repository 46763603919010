import { call } from 'redux-saga/effects';
import { requestWhiteboardData } from 'features/whiteboard/actions';
import { SignalingSocket } from 'services/signaling';

export function* requestWhiteboardDataSaga(action: ReturnType<typeof requestWhiteboardData>) {
  yield call(SignalingSocket.send, {
    event: 'requestWhiteboardData',
    data: { id: action.payload },
  });
}
