import { takeEvery } from 'redux-saga/effects';

import {
  requestWhiteboardData,
  signalingWhiteboardDataReceived,
} from 'features/whiteboard/actions';

import { onWhiteboardDataReceivedSaga } from './onWhiteboardDataReceivedSaga';
import { requestWhiteboardDataSaga } from './requestWhiteboardDataSaga';

export default function* whiteboardWatcher() {
  yield takeEvery(signalingWhiteboardDataReceived, onWhiteboardDataReceivedSaga);
  yield takeEvery(requestWhiteboardData, requestWhiteboardDataSaga);
}
