import { useFeatureFlag } from 'features/feature-set/useFeatureFlag';
import { PermissionTypes } from 'features/permissions/types';
import { usePermissions } from 'features/permissions/usePermissions';
import { useRecorder } from 'features/recorder/useRecorder';
import { GridPanelControl } from 'features/layout/GridPanel/GridPanelControl';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fileClosed,
  selectContentLibraryOpenedFile,
} from 'features/content-library/contentLibrarySlice';

export const useFilePanelCloseControl = () => {
  const dispatch = useAppDispatch();
  const isRecorder = useRecorder();

  const { hasPermissions } = usePermissions();

  const canPresent = hasPermissions(PermissionTypes.manageContentLibrary);

  const isFeatureEnabled = useFeatureFlag('contentLibrary');

  const controlEnabled = isRecorder ? false : isFeatureEnabled && canPresent;

  const openedFile = useAppSelector(selectContentLibraryOpenedFile);

  const handleClick = () => {
    if (openedFile) {
      dispatch(fileClosed(openedFile.id));
    }
  };

  return { controlEnabled, handleClick };
};

export const ContentCloseControl = () => {
  const { controlEnabled, handleClick } = useFilePanelCloseControl();
  return controlEnabled ? <GridPanelControl icon="close" onClick={handleClick} /> : null;
};
