import { PayloadAction } from '@reduxjs/toolkit';
import { gridPanelOpened } from 'features/layout/features/content/contentSlice';
import {
  contentWidthChanged,
  selectPinnedContentWidth,
} from 'features/layout/features/contentDimensions/contentDimensionsSlice';
import {
  screenshareAdded,
  selectScreenshareStreams,
} from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';
import { screenshareSelected } from 'features/streaming/actions';
import { StreamingStarted } from 'features/streaming/types';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';

export function* onScreensharingStartSaga(action: PayloadAction<StreamingStarted>) {
  const { id: userId, feedId, streams } = action.payload;

  yield call(RTCClient.receivingFeed.addAvailableStreams, feedId, streams);
  RTCClient.receivingFeed.availableScreenshares[feedId] = streams;

  if (!RTCClient.localFeeds[feedId]) {
    RTCClient.screensharingFeed.pendingReconnect = false;
  }

  yield put(screenshareSelected({ userId, feedId }));

  const source: SourceDetails = {
    userId: action.payload.id,
    feedId,
    kind: `screenshare-${RTCClient.localFeeds[feedId] ? 'local' : 'remote'}`,
  };

  const screenshares: SourceDetails[] = yield select(selectScreenshareStreams);

  const knownScreenshare = screenshares.find((stream) => stream.feedId === feedId);

  yield put(screenshareAdded(source));

  if (!knownScreenshare) {
    yield put(gridPanelOpened('screenshare'));
  }

  // TODO;

  const pinnedContentWidth: number = yield select(selectPinnedContentWidth);
  yield put(contentWidthChanged(pinnedContentWidth));
  yield call(eventBus.sendMessage, 'screenshareStarted', { userId });
}
