import { PayloadAction } from '@reduxjs/toolkit';
import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import {
  screenshareDeleted,
  selectScreenshareStreams,
} from 'features/layout/features/order/orderSlice';
import { updateContentSizeSaga } from 'features/layout/sagas/updateContentSizeSaga';
import { SourceDetails } from 'features/layout/types';
import { call, put, select } from 'redux-saga/effects';
import { eventBus } from 'utils/eventBus';
import { RTCClient } from 'utils/webrtc';
import { selectE2eeEnabled } from 'features/e2ee/e2eeSlice';
import { User } from 'features/users/types';
import { ScreenshareStopped } from 'features/streaming/types';
import { openLastActiveScreenshareSaga } from './openLastActiveScreenshareSaga';

export function* onScreensharingStopSaga(action: PayloadAction<ScreenshareStopped>) {
  const { id: userId, feedId, streams } = action.payload;

  const stoppedStream: SourceDetails = {
    userId,
    feedId,
    kind: 'local',
  };

  yield call(updateContentSizeSaga, stoppedStream);

  yield put(screenshareDeleted(stoppedStream));

  RTCClient.receivingFeed.subscribedScreenshares[feedId] = false;

  const remainingScreenshares: (SourceDetails & { user: User })[] =
    yield select(selectScreenshareStreams);

  if (!remainingScreenshares.length) {
    yield put(gridPanelVoided('screenshare'));
  } else {
    yield call(openLastActiveScreenshareSaga);
  }

  yield call(eventBus.sendMessage, 'screenshareStopped', { userId });

  const e2eeEnabled: boolean = yield select(selectE2eeEnabled);

  for (const stream of streams) {
    yield call(RTCClient.disableScreensharingMedia, userId, stream.type);
    yield call(RTCClient.receivingFeed.removeStreamTrack, feedId, stream.mid);

    if (e2eeEnabled) {
      yield call(RTCClient.receivingFeed.removeE2eePendingSubscription, {
        userId,
        feedId,
        mid: stream.mid,
      });
    }
  }
}
