import { createAction } from '@reduxjs/toolkit';
import { CursorUpdatePayload } from 'utils/whiteboard/BoardStateManager';
import { WhiteboardCreatedPayload } from 'features/content-library/types';
import {
  RemoteWBUpdatePayload,
  SignalingWhiteboardOpenedPayload,
  WhiteboardAlreadyOpenedPayload,
  WhiteboardCreatedOptions,
  SignalingWhiteboardClosedPayload,
  SignalingContentPushedPayload,
} from './types';

export const whiteboardOpened = createAction<string>('layout/whiteboardOpened');
export const whiteboardClosed = createAction<string>('layout/whiteboardClosed');
export const whiteboardCreated = createAction<WhiteboardCreatedOptions>('layout/whiteboardCreated');

export const signalingWhiteboardOpened = createAction<SignalingWhiteboardOpenedPayload>(
  'signaling/whiteboardOpened'
);
export const signalingWhiteboardClosed = createAction<SignalingWhiteboardClosedPayload>(
  'signaling/whiteboardClosed'
);
export const signalingWhiteboardCreated = createAction<WhiteboardCreatedPayload>(
  'signaling/whiteboardCreated'
);
export const signalingWhiteboardChanged = createAction<RemoteWBUpdatePayload>(
  'signaling/whiteboardChanged'
);

export const signalingWhiteboardCursorUpdated = createAction<CursorUpdatePayload>(
  'signaling/whiteboardCursorUpdated'
);
export const signalingWhiteboardAlreadyOpened = createAction<WhiteboardAlreadyOpenedPayload>(
  'signaling/whiteboardAlreadyOpened'
);

export const contentPushed = createAction('layout/contentPushed');

export const signalingContentPushed =
  createAction<SignalingContentPushedPayload>('signaling/contentPushed');
