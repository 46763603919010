import { put, select } from 'redux-saga/effects';
import { signalingContentPushed } from 'features/layout/features/content/actions';
import { gridPanelOpened, selectGridPanel } from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { fileViewed, whiteboardViewed } from 'features/content-library/actions';
import { screenshareSelected } from 'features/streaming/actions';
import { selectScreenshareStreams } from 'features/layout/features/order/orderSlice';
import { SourceDetails } from 'features/layout/types';

const panelMap: { [key: string]: GridPanelName } = {
  file: 'contentLibrary',
  whiteboard: 'whiteboard',
  screenshare: 'screenshare',
};

export function* onContentPushedSaga(action: ReturnType<typeof signalingContentPushed>) {
  const { type, id } = action.payload;

  const currentPanel: GridPanelName = yield select(selectGridPanel);
  const nextPanel: GridPanelName = panelMap[type];

  if (nextPanel !== currentPanel) {
    yield put(gridPanelOpened(nextPanel));
  }

  if (type === 'screenshare') {
    const screenShares: (SourceDetails & { feedId: string })[] =
      yield select(selectScreenshareStreams);

    const screenShare = screenShares.find((item) => item.feedId === id);

    if (screenShare) {
      yield put(screenshareSelected({ feedId: screenShare.feedId, userId: screenShare.userId }));
    }

    return;
  }

  if (type === 'whiteboard') {
    yield put(whiteboardViewed(id));
    return;
  }

  if (type === 'file') {
    yield put(fileViewed(id));
  }
}
