import { call, select } from 'redux-saga/effects';
import { gridPanelVoided } from 'features/layout/features/content/contentSlice';
import { openLastActiveWhiteboardSaga } from 'features/content-library/sagas/openLastActiveWhiteboardSaga';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';

export function* onGridPanelVoidedSaga(action: ReturnType<typeof gridPanelVoided>) {
  if (action.payload === 'screenshare') {
    const openedFile: ContentLibraryOpenedFile = yield select(selectContentLibraryOpenedFile);

    if (openedFile) {
      if (openedFile.type === 'whiteboard') {
        yield call(openLastActiveWhiteboardSaga);
      } else {
        yield call(openLastActiveFileSaga);
      }
    }
  }
}
