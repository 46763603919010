import { call, select } from 'redux-saga/effects';
import { selectGridPanel } from 'features/layout/features/content/contentSlice';
import { GridPanelName } from 'features/layout/features/content/types';
import { ContentLibraryOpenedFile } from 'features/content-library/types';
import { selectContentLibraryOpenedFile } from 'features/content-library/contentLibrarySlice';
import { SelectedScreenshare } from 'features/streaming/types';
import { selectSelectedScreenshare } from 'features/streaming/streamingSlice';
import { SignalingSocket } from 'services/signaling';

export function* pushContentSaga() {
  const currentPanel: GridPanelName = yield select(selectGridPanel);

  let contentType;
  let contentId;

  if (currentPanel === 'contentLibrary' || currentPanel === 'whiteboard') {
    const openedFile: ContentLibraryOpenedFile = yield select(selectContentLibraryOpenedFile);

    contentId = openedFile.id;
    contentType = currentPanel === 'contentLibrary' ? 'file' : 'whiteboard';
  } else {
    const screenShare: SelectedScreenshare = yield select(selectSelectedScreenshare);

    contentId = screenShare.feedId;
    contentType = 'screenshare';
  }

  yield call(SignalingSocket.send, {
    event: 'pushContent',
    data: { type: contentType, id: contentId },
  });
}
