import { onContentLibraryFileViewedSaga } from 'features/content-library/sagas/onContentLibraryFileViewedSaga';
import { openLastActiveFileSaga } from 'features/content-library/sagas/openLastActiveFileSaga';
import { takeEvery } from 'redux-saga/effects';
import { createLibraryFolderSaga } from 'features/content-library/sagas/createLibraryFolderSaga';
import {
  cancelContentLibraryFileProcessingRequested,
  contentLibraryChangeFilePageRequested,
  contentLibraryFileOpenRequested,
  contentLibraryFileUploadRequested,
  createContentFolderRequested,
  createEmbedContentRequested,
  deleteContentLibraryFileRequested,
  deleteContentLibraryFolderRequested,
  downloadContentLibraryFileRequested,
  fileViewed,
  renameContentFolderRequested,
  renameContentLibraryFileRequested,
  signalingContentLibraryFileClosed,
  signalingContentLibraryFileOpened,
  signalingContentLibraryOpenedFilesReceived,
  signalingContentLibraryVideoPlayerStateChanged,
  whiteboardViewed,
} from 'features/content-library/actions';
import { renameLibraryFolderSaga } from 'features/content-library/sagas/renameLibraryFolderSaga';
import { renameContentLibraryFileSaga } from 'features/content-library/sagas/renameContentLibraryFileSaga';
import { deleteContentLibraryFileSaga } from 'features/content-library/sagas/deleteContentLibraryFileSaga';
import { deleteContentLibraryFolderSaga } from 'features/content-library/sagas/deleteContentLibraryFolderSaga';
import { uploadContentLibraryFilesSaga } from 'features/content-library/sagas/uploadContentLibraryFilesSaga';
import { embedPlayerStateChanged, fileClosed } from 'features/content-library/contentLibrarySlice';
import { cancelContentLibraryFileSaga } from 'features/content-library/sagas/cancelContentLibraryFileSaga';
import { openContentLibraryFileSaga } from 'features/content-library/sagas/openContentLibraryFileSaga';
import { onContentLibraryFileOpenedSaga } from 'features/content-library/sagas/onContentLibraryFileOpenedSaga';
import { onContentLibraryFileClosedSaga } from 'features/content-library/sagas/onContentLibraryFileClosedSaga';
import { closeContentLibraryFileSaga } from 'features/content-library/sagas/closeContentLibraryFileSaga';
import { changeContentLibraryFileSaga } from 'features/content-library/sagas/changeContentLibraryFilePageSaga';
import { createLibraryEmbedContentSaga } from 'features/content-library/sagas/createLibraryEmbedContentSaga';
import { downloadContentLibraryFileSaga } from 'features/content-library/sagas/downloadContentLibraryFileSaga';
import { embedPlayerStateChangeSaga } from 'features/content-library/sagas/embedPlayerStateChangeSaga';
import { onContentLibraryVideoPlayerRemoteStateChangedSaga } from 'features/content-library/sagas/onContentLibraryVideoPlayerRemoteStateChangedSaga';
import { onWhiteboardViewedSaga } from './onWhiteboardViewedSaga';

export default function* contentLibraryWatcher() {
  // manage
  yield takeEvery(createContentFolderRequested, createLibraryFolderSaga);
  yield takeEvery(renameContentFolderRequested, renameLibraryFolderSaga);
  yield takeEvery(deleteContentLibraryFolderRequested, deleteContentLibraryFolderSaga);
  yield takeEvery(renameContentLibraryFileRequested, renameContentLibraryFileSaga);
  yield takeEvery(deleteContentLibraryFileRequested, deleteContentLibraryFileSaga);

  yield takeEvery(createEmbedContentRequested, createLibraryEmbedContentSaga);

  // upload
  yield takeEvery(contentLibraryFileUploadRequested, uploadContentLibraryFilesSaga);
  yield takeEvery(cancelContentLibraryFileProcessingRequested, cancelContentLibraryFileSaga);

  // open file
  yield takeEvery(contentLibraryFileOpenRequested, openContentLibraryFileSaga);
  yield takeEvery(contentLibraryChangeFilePageRequested, changeContentLibraryFileSaga);
  yield takeEvery(signalingContentLibraryFileOpened, onContentLibraryFileOpenedSaga);
  yield takeEvery(signalingContentLibraryOpenedFilesReceived, openLastActiveFileSaga);

  yield takeEvery(signalingContentLibraryFileClosed, onContentLibraryFileClosedSaga);
  yield takeEvery(fileClosed, closeContentLibraryFileSaga);
  yield takeEvery(fileViewed, onContentLibraryFileViewedSaga);
  yield takeEvery(whiteboardViewed, onWhiteboardViewedSaga);

  yield takeEvery(downloadContentLibraryFileRequested, downloadContentLibraryFileSaga);

  yield takeEvery(embedPlayerStateChanged, embedPlayerStateChangeSaga);
  // yield takeEvery(embedPlayerReady, embedPlayerStateChangeSaga);
  yield takeEvery(
    signalingContentLibraryVideoPlayerStateChanged,
    onContentLibraryVideoPlayerRemoteStateChangedSaga
  );
}
