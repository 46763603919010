import { screenshareStopped } from 'features/streaming/actions';
import { getDisplayMedia } from 'features/user-media/utils/getDisplayMedia';
import { selectLocalUserId } from 'features/users/usersSlice';
import { store } from 'store/store';
import { logger } from 'utils/logger';
import { FeedMediaState, RTCClient } from 'utils/webrtc/index';
import { BasePublishing } from 'utils/webrtc/publishing';

export class ScreensharingFeed extends BasePublishing {
  defaultMediaState: FeedMediaState = {
    enabled: true,
    allowed: true,
    broadcasting: false,
    captured: false,
  };

  mediaStates = {
    video: { ...this.defaultMediaState },
    audio: { ...this.defaultMediaState },
  };

  savedStream: MediaStream | null = null;

  pendingReconnect: boolean = false;

  constructor() {
    super('screensharing');
  }

  getActiveMediaConfig = () => {
    if (this.savedStream) {
      const clonedStream = new MediaStream();

      this.savedStream.getTracks().forEach((track) => clonedStream.addTrack(track.clone()));

      this.mediaController.storedMediaStreams[clonedStream.id] = clonedStream;

      return {
        stream: clonedStream,
      };
    }

    logger.remote().error(`Missing stored stream in screensharing feed`);

    return {
      video: false,
      audio: false,
    };
  };

  shareScreen = async () => {
    this.broadcastIntended = true;

    if (this.pendingReconnect) {
      logger.remote().warn('[screenshare-debug]: starting branch A');
      this.cleanupConnection();
      this.attachPlugin();
      this.pendingReconnect = false;
      logger.remote().warn('[screenshare-debug]: starting branch A done');
    } else if (this.handle) {
      logger.remote().warn('[screenshare-debug]: starting branch B');

      this.resetMediaStates();
      // TODO: Maybe reset negotioation meta in here?; or maybe reset media states is not necessary

      await this.requestPublish({
        media: { replaceVideo: true, replaceAudio: true },
      });

      this.pendingReconnect = false;
      logger.remote().warn('[screenshare-debug]: starting branch B done');
    } else {
      logger.remote().warn('[screenshare-debug]: starting branch C');

      this.attachPlugin();
      logger.remote().warn('[screenshare-debug]: starting branch C done');
    }
  };

  stopScreenshare = async (notifySignaling: boolean = true) => {
    this.broadcastIntended = false;

    this.savedStream?.getTracks().forEach((track) => {
      track.stop();
    });

    logger.remote().warn('[screenshare-debug]: remove saved stream');
    this.savedStream = null;

    if (this.handle?.feedId) {
      if (notifySignaling) {
        this.signalingBus.addMessage(
          'stop',
          this.handle.feedId,
          Object.values(this.handle.streams)
        );
      }

      const userId = selectLocalUserId(store.getState());

      store.dispatch(
        screenshareStopped({
          id: userId,
          feedId: this.handle.feedId,
          streams: Object.values(this.handle.streams),
        })
      );
    }

    try {
      await this.requestPublish({
        media: {
          videoSend: false,
          removeVideo: true,
          audioSend: false,
          removeAudio: true,
        },
      });
    } catch (e) {
      // do nothing, it's ok
    }

    this.cleanupStreams();

    if (this.mediaStates.video.broadcasting) {
      this.stopVideoBroadcasting();
    } else {
      this.resetMediaStates('video');
    }

    if (this.mediaStates.audio.broadcasting) {
      this.stopAudioBroadcasting();
    } else {
      this.resetMediaStates('audio');
    }

    this.updateRedux();
  };

  toggleScreenshare = async () => {
    if (this.mediaStates.video.broadcasting) {
      await this.stopScreenshare();
    } else {
      await this.shareScreen();
    }
  };

  onMediaState = async (medium: 'audio' | 'video', on: boolean, mid?: string) => {
    logger
      .remote()
      .debug(`Janus ${on ? 'started' : 'stopped'} receiving our ${medium} (mid=${mid})`);

    const feed = this.handle;

    if (!feed) {
      return;
    }

    feed.streams = feed.streams || {};

    if (on) {
      RTCClient.resetPublishOptions();

      if (mid) {
        if (medium === 'audio' && this.negotiationMeta.pendingAudioBroadcastState !== null) {
          logger.warn(
            'ON_MEDIA_STATE tweaking audio with pending state...',
            this.negotiationMeta.pendingAudioBroadcastState
          );

          if (this.negotiationMeta.pendingAudioBroadcastState) {
            feed.videoroom.unmuteAudio(mid);
          } else {
            feed.videoroom.muteAudio(mid);
          }

          this.negotiationMeta.pendingAudioBroadcastState = null;
        }

        feed.streams[mid] = { type: medium, mid, on: true };
      }

      if (!feed.connected && !feed.connecting) {
        feed.connecting = true;
      }
    } else {
      feed.streams[mid!].on = false;
    }

    const shouldSendUpdate = on ? !this.mediaStates[medium].captured : false;

    this.mediaStates[medium].broadcasting = on;
    this.mediaStates[medium].captured = on;

    if (mid && shouldSendUpdate) {
      this.signalingBus.addMessage(on ? 'start' : 'stop', feed.feedId, [{ type: medium, mid }]);
    }

    this.updateRedux();
  };

  cleanupStreams = () => {
    Object.keys(this.mediaController.streams).forEach((id) => {
      this.mediaController.removeStream(id);
    });

    Object.keys(this.mediaController.storedMediaStreams).forEach((id) => {
      this.mediaController.stopStreamTracks(this.mediaController.storedMediaStreams[id]);
    });
  };

  cleanupConnection = () => {
    logger.debug('Screensharing Feed: cleaning up connection');
    const feed = this.handle;
    this.resetMediaStates();

    this.handleCleanup();

    this.reconnectionReset();

    if (!feed) {
      return;
    }

    if (feed.feedId) {
      RTCClient.disconnectFeed(feed.feedId);
    }

    feed.videoroom.hangup();
  };

  saveStream = async () => {
    this.savedStream = await getDisplayMedia();
  };
}
